// eslint-disable-next-line
import { SetupConfigBuilder, Log, LogTheme, PlayerEventType, CueType } from '@top/player-block-web'

export function getQueryParam(name) {
  var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results == null) {
      return null;
  } else {
      return decodeURI(results[1]) || 0;
  }
}

Log.setEnabled(false)
Log.setTheme(LogTheme.Dark)

export class Standard {
  /**
   * @param {SetupConfigBuilder} config 
   */
  static addDebugConfiguration = (config) => {
    config.withDebug({ enabled: false })
  }

  /**
   * Set up pretty print logging for player events
   * @param {Player} player 
   */
  static addEventListeners = (player) => {

    player.events.listen((type, result) => {
      if (suppress.some(el => el === type))
        return

      if (type === PlayerEventType.Message_From_UI) {
        console.debug(`[${type}] "${result.name}"`, result.payload)
        return
      }

      console.debug(`[${type}]`, getResultMessage(type, result))
    })
  }
}

// List of event types (verbose) to prevent from showing up in the logging
const suppress = [
  PlayerEventType.Media_Time_Changed,
  PlayerEventType.Ad_Time_Changed,
  // PlayerEventType.Cue_Processed,
  // PlayerEventType.Cue_Activated,
  PlayerEventType.Cue_State_Changed,
  PlayerEventType.Model_Updated,
  PlayerEventType.Media_Timed_Metadata_Parsed
]

// Pretty print timed metadata cues
const getCueMessage = (result) => {
  let type = result.cue.type
  let msg = type
  if (type === CueType.SCTE) {
    let descriptors = result.cue.data.content.metadata.splice_descriptors.map(desc => {
      return desc.segmentation_type_id_name
    })

    if (descriptors.length > 0)
      msg += ` (${descriptors.join(" && ")})`
  }
  return msg
}

// Pretty print result data
const getResultMessage = (type, result) => {
  if (result.hasOwnProperty('cue')) {
    if (type === PlayerEventType.Cue_Processed)
      return `${result.cue.id}, ${getCueMessage(result)}, trigger: ${result.cue.data.range.start}`
    else
      return `${result.cue.id}, ${getCueMessage(result)}`
  } 
  else if (result.hasOwnProperty('currentState')) return result.previousState + ' -> ' + result.currentState
  else if (result.hasOwnProperty('currentValue')) return result.previousValue + ' -> ' + result.currentValue
  else if (result.hasOwnProperty('time')) return result.time
  else if (result.hasOwnProperty('volume')) return result.volume
  else if (result.hasOwnProperty('muted')) return result.muted
  else if (result.hasOwnProperty('command')) return result.command
  else if (result.hasOwnProperty('profile')) return result.profile.bitrate
  else if (result.hasOwnProperty('marker')) return JSON.stringify(result.marker)
  else if (result.hasOwnProperty('settings')) return JSON.stringify(result.settings)
  else if (result.hasOwnProperty('description')) return result.description
  else if (result.hasOwnProperty('error')) return result.error.toString()

  return result
}

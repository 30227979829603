// @flow
import React, { Suspense, useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/app/Header';
import Home from './components/app/Home';
import ActivityIndicator from './components/app/ActivityIndicator';
import AppContainer from './components/app/AppContainer';
import { AuthenticatedRoute } from './components/app/AuthenticatedRoute'
// /* lazy load scenarios */
import Telemetry from './components/scenarios/Telemetry/Telemetry';
import TestCase01 from './components/scenarios/Telemetry/testCases/TestCase01';
import TestCase02 from './components/scenarios/Telemetry/testCases/TestCase02';
//import addScript from './components/addScript';
import addPsmMgr from './components/addPsmMgr'

const App = () => {
  useEffect(() => {
    //This script will add psm.min.js in the index.html file. 
    //TODO: Should remove this if exist at Home.jsx file???
    addPsmMgr(true)
    //This script will add psm.legacy.min.js in the index.html file.
    // addScript() //FIXME
 //If always is true, no need dependency
 }, [])

  return (
    <Router>
      <Header />
      <AppContainer>
        <Switch>
          <Suspense fallback={<ActivityIndicator />}>
            <Route exact path="/" component={Home} />
            {/*
              * Currently AuthenticatedRoutes are dynamically checked, so when new
              * scenarios are completed they need to add the requiresAuth static property
              * so they're correctly identified when routing and we bypass scenarios
              * that don't require auth at all, i.e. basic VOD by URL
              */}
            <AuthenticatedRoute path="/telemetry" component={Telemetry} />
            <AuthenticatedRoute path="/testCase01" component={TestCase01} />
            <AuthenticatedRoute path="/testCase02" component={TestCase02} />
          </Suspense>
        </Switch>
      </AppContainer>
    </Router>
  );
};

export default App;

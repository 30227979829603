import { CHANGE_PSM_ENVIRONMENT } from "./psmControl.action";
import { PSM_INITIALIZED } from "./psmControl.action"

const initialState = {
    psm_environment: 'TEST',
    psm_initialized: false
}

export default (state=initialState, action) => {
    switch (action.type) {
        case CHANGE_PSM_ENVIRONMENT:
            return {
                ...state,
                psm_environment: action.payload,
            }
        case PSM_INITIALIZED:
            return {
                ...state,
                psm_initialized: true,
            }
        default:
            return state;
    }
}
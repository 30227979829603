// @flow

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { searchMetadata } from '../../redux/getMetadata/getMetadata.action';

const SearchBoxContainer = styled.div`
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 1rem;
  -webkit-box-shadow: 4px 2px 10px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 4px 2px 10px 1px rgba(0, 0, 0, 0.14);
  box-shadow: 4px 2px 10px 1px rgba(0, 0, 0, 0.14);
`;

type Props = {
  searchScenario: (data: string) => void,
};

const SearchBox = ({ searchScenario }: Props) => {
  const handleSearch = (event: any) => {
    searchScenario(event.target.value);
  };

  return (
    <SearchBoxContainer>
      <input
        type="text"
        placeholder="Search scenario..."
        className="form-control mr-sm-2"
        onChange={handleSearch}
      />
    </SearchBoxContainer>
  );
};

const mapDispatch = dispatch => {
  return {
    searchScenario: (data: string) => dispatch(searchMetadata(data)),
  };
};
export default connect(
  null,
  mapDispatch
)(SearchBox);

import { Standard } from '../../../app/Standard'
import { AuthService } from '../../../app/AuthService'
import * as Constants from '../../../app/Constants'
import { Player, PlayerConfigBuilder, ContentEntryBuilder } from '@top/player-block-web'
import React from 'react';
import StandardPlayerContainer from '../../../shared/StandardPlayerContainer';
import { mockedProgramMetadataConvivaTags } from '../../../../utils/mocked'


export class TestCase02VODWithAds {

  static setup = () => {
    this.player1 = new Player()
    this.player2 = new Player()

    let setupConfig1 = PlayerConfigBuilder.forSetup()
      .withContainer(document.getElementById('player-container'))
      .withMetadata({ appId: Constants.APP_ID })
    let setupConfig2 = PlayerConfigBuilder.forSetup()
      .withContainer(document.getElementById('player-container2'))
      .withMetadata({ appId: Constants.APP_ID })

    Standard.addDebugConfiguration(setupConfig1)
    Standard.addDebugConfiguration(setupConfig2)

    this.player1.setup(setupConfig1.build())
    this.player2.setup(setupConfig2.build())
    
    window.TopPlayer1 = this.player1
    window.TopPlayer2 = this.player2
    
    this.playContent()
  }

  static playContent = () => {

    AuthService.authorize().then((result) => {

      const playConfig1 = PlayerConfigBuilder.forPlay()
        .withAds({ profile: 'eaMMDsbsd0' })
        .withMetadata({
          env: 'ite',
          companyID: '369463fd-e0a2-4ce9-9ad4-e698aabe5a77',
        })
        .withConviva({
          customerKey: '8a941d3b161c486d426c0a359d9d39bae51186b5',
          touchstoneUrl: 'https://turner-test.testonly.conviva.com',
          applicationName: 'DuoApp - Player 1 - Web',
          assetName: 'Twitter',
          tags: mockedProgramMetadataConvivaTags
        })
        .build()

      const contentEntryOptions = ContentEntryBuilder.forEntryOptions()
        .withAccessTokenType('adobe')
        .withAccessToken(result.token)
        .withPlayConfigOverrides(playConfig1)
        .build();

      this.player1.playByMediaJson({ mediaId: "test8d7ea84d5b95e9459a7189f39640dd1f124efdda" }, contentEntryOptions)

      const playConfig2 = PlayerConfigBuilder.forPlay()
        .withAds({ profile: 'fnOzKDdjvS' })
        .withConviva({
          customerKey: '8a941d3b161c486d426c0a359d9d39bae51186b5',
          touchstoneUrl: 'https://turner-test.testonly.conviva.com',
          applicationName: 'DuoApp - Player 2 - Web',
          assetName: 'Basketball Game',
          tags: mockedProgramMetadataConvivaTags
        })
        .build()

      const contentEntryOptions2 = ContentEntryBuilder.forEntryOptions()
        .withAccessTokenType('adobe')
        .withAccessToken(result.token)
        .withPlayConfigOverrides(playConfig2)
        .build();

      this.player2.playByMediaJson({ mediaId: "medac9fd546f7961a1955ecc2875f56a8d28415135" }, contentEntryOptions2)


      this.playEventsSelected(window.TopPlayer1)
      this.playEventsSelected(window.TopPlayer2)

    })
  }

  static playEventsSelected = (player) => {
    const { id } = player

    player.events.listen({
      playerReady: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: playerReady`);
      },
      adLoaded: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: Ad loaded`);
      },
      adFinished: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: adFinished`);
      },
      contentBeginning: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: contentBeginning`);
      },
      contentCompleted: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: contentCompleted`);
      },
      contentEnded: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: contentEnded`);
      },
      mediaStarted: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: mediaStarted`);
      },
      mediaLoaded: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: mediaLoaded`);
      },
      mediaSeekingStarted: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: mediaSeekingStarted`);
      },
      mediaSeekingFinished: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: mediaSeekingFinished`);
      },
      mediaStarting: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: mediaStarting`);
      },
      mediaPaused: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: mediaPaused`);
      },
      mediaResumed: function (result) {
        console.log(`====> DuoApp TopPlayer version ${id} - Player Event: mediaResumed`);
      },
    })
  }

  static stopListeners = (id) => {
    if (id === 1) {
      this.player1.events.unlistenAll()
      console.log('=========================>>> PSM: unlistenAll from player ', id)
    }
    if (id === 2) {
      this.player2.events.unlistenAll()
      console.log('=========================>>> PSM: unlistenAll from player ', id)
    }
  }

  static cleanup = () => {
    this.player1.destroy()
    this.player2.destroy()
    this.player1 = null
    this.player2 = null
  }

  /**
   * 
   * Method 02 - initializing prism video/telemetry by clicking in UI button
   * 
   **/ 
  static initializationPlayerSpecific = (id) => {
    console.log('Initiating Telemetry in player ',id)
    if (id === 1) {
      window.psmVideo.top2.startMonitoring(this.player1);
    }
    if (id === 2) {
      window.psmVideo.top2.startMonitoring(this.player2);
    }
  }

  static html = () => (
    <StandardPlayerContainer />
  )
}

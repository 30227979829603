const asyncInterval = async (doesThisExist, ms, triesLeft = 10) => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      if (doesThisExist) {
        resolve();
        clearInterval(interval);
      } else if (triesLeft <= 1) {
        reject();
        clearInterval(interval);
      }
      triesLeft--;
    }, ms);
  });
}

export default asyncInterval;

// @flow
import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import rootEpic from '../epics';
import rootReducer from './rootReducer';

const epicMiddleware = createEpicMiddleware();

export default function configureStore(preloadedState: Object) {
  const middlewares = [epicMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const store = createStore(rootReducer, preloadedState, composeWithDevTools(middlewareEnhancer));
  epicMiddleware.run(rootEpic);
  return store;
}

import {
  GET_METADATA,
  GET_METADATA_SUCCESS,
  GET_METADATA_ERROR,
  SEARCH_METADATA,
} from './getMetadata.action';

const initialState = {
  categories: [],
  isFetching: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_METADATA:
      return {
        ...state,
        isFetching: true,
      };

    case GET_METADATA:
      return {
        ...state,
        isFetching: true,
      };
    case GET_METADATA_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
        isFetching: false,
      };
    }
    case GET_METADATA_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

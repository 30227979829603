export const mockedProgramMetadataConvivaTags = {
  "adobeId": "1234567890",
  "seasonNumber": "01",
  "episodeNumber": "02",
  "channel": "TBS",
  "freePreview": "false",
  "genre": "sports",
  "highlanderId": "98349382y47r32rh92h39hd9",
  "series": "2019",
  "seriesSource": "linear",
  "show": "Twister",
  "streamProtocol": "CMAF",
  "advertisingId": "89h8923dh7ghuiub",
  "affiliate": "Nike",
  "appName": "Watch TBS",
  "appVersion": "4.2.3",
  "authRequired": true,
  "authState": true,
  "contentId": "23y9e8y298ey2rhhh",
  "contentType": "HLS",
  "kruxId": "dh389dh9283hd29h",
  "mvpdId": "Dish",
  "seriesTitleId": "234455553",
  "videoTitleId": "123123783",
  "videoTitle": "Twister",
  "autoplay": "true",
  "preload": "false",
  "carrier": "AT&T",
  "connectionType": "wifi"
}

export const convivaCommonData = {
  customerKey: '8a941d3b161c486d426c0a359d9d39bae51186b5',
  touchstoneUrl: 'https://turner-test.testonly.conviva.com',
  tags: mockedProgramMetadataConvivaTags
}

const addPsmMgr = (coppa) => {
  // const baseURL = "https://lightning.tbs.com/cdp/psm/brands/tbs/web/pre/psm.min.js";
  const localDir = "/data";
  const filenameURL = coppa ? "/psm-mgr.js" : "/psm-mgr-no-coppa.js";
  const baseURL = localDir + filenameURL;

  const script = document.createElement("script")
  script.type = "text/javascript"
  script.async = true
  script.src = baseURL
  script.id = "localPsmMgrScript"
  
  document.head.appendChild(script)
}

export default addPsmMgr

// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Category from '../shared/Category';
import CardScenarios from '../shared/CardScenarios';
import { getMetadataAction } from '../../redux/getMetadata/getMetadata.action';
import ActivityIndicator from './ActivityIndicator';
import SearchBox from './SearchBox';
import preval from 'preval.macro'
import { changePsmEnvironment } from '../../redux/psmControl/psmControl.action';
import addPsmMgr from '../addPsmMgr'


const StyledEnvironmentSelectorButton = styled.button`
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid white;
    border-radius: 3px;
    color: white;
    background-color: blue;
`;

const BuildTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`

export class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      coppaEnabled: true,
    }
  }


  componentDidMount() {
    const {
      getMetadata,
    } = this.props;
    getMetadata();
  }

  buildSections = (sections) => {
    return sections.map(aSection => {
      const { scenarios, title } = aSection;
      return (
        <Category data={aSection} key={title} headingLevel={4}>
          <CardScenarios data={scenarios} />
        </Category>
      );
    });
  };

  buildCategories = (aCategory) => {
    const { sections, title } = aCategory;
    return (
      <Category data={aCategory} key={title} headingLevel={2}>
        {this.buildSections(sections)}
      </Category>
    );
  };

  handleChange = (checked) => {
    const {
      getMetadata,
    } = this.props;
    getMetadata();
  };

  psmButtonClick = (environment) => {
    this.props.changePsmEnvironment(environment)
  }

  handleCoppaMode(e) {
    this.setState({coppaEnabled: e.target.checked})
    let psmMgrScript = document.getElementById("localPsmMgrScript");
    psmMgrScript.parentNode.removeChild(psmMgrScript);
    addPsmMgr(e.target.checked)
  }

  render() {
    const {
      metaData: { categories, isFetching },
    } = this.props;

    return (
      <>
        <Row>
          <Col>
            <SearchBox />
          </Col>
          <Col style={{alignSelf: 'center', display: 'flex', justifyContent: 'center'}}>
            <label style={{fontWeight: 'bold'}}>
            <span style={{ marginRight: '15px'}}>Allow Coppa</span>
              <input style={{cursor: 'pointer'}} type="checkbox" defaultChecked={this.state.coppaEnabled} onChange={e => this.handleCoppaMode(e)} />
            </label>
          </Col>
        </Row>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <h5>Prism Environment</h5>
        </div>
        {this.props.psmInitialized ? <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><p>Prism has already been initialized. Reload the webpage to change environments.</p></div> : null}
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <StyledEnvironmentSelectorButton style={this.props.psmEnvironment === 'PROD' ? { backgroundColor: 'green' } : (this.props.psmInitialized ? { backgroundColor: 'grey', cursor: 'default' } : null)} onClick={() => (this.props.psmInitialized ? null : this.psmButtonClick('PROD'))}>PROD</StyledEnvironmentSelectorButton>
          <StyledEnvironmentSelectorButton style={this.props.psmEnvironment === 'DEV' ? { backgroundColor: 'green' } : (this.props.psmInitialized ? { backgroundColor: 'grey', cursor: 'default' } : null)} onClick={() => (this.props.psmInitialized ? null : this.psmButtonClick('DEV'))}>DEV</StyledEnvironmentSelectorButton>
          <StyledEnvironmentSelectorButton style={this.props.psmEnvironment === 'TEST' ? { backgroundColor: 'green' } : (this.props.psmInitialized ? { backgroundColor: 'grey', cursor: 'default' } : null)} onClick={() => (this.props.psmInitialized ? null : this.psmButtonClick('TEST'))}>QA</StyledEnvironmentSelectorButton>
        </div>
        <Row>
          <Col>
            {isFetching && <ActivityIndicator />}
            {categories.map(this.buildCategories)}
          </Col>
        </Row>
        <BuildTime>
          <small>Last Updated: <b>{preval`module.exports = new Date().toString();`}</b></small>
        </BuildTime>
      </>
    );
  }
}

const mapState = state => {
  const { metadataReducer } = state;
  return {
    metaData: metadataReducer,
    psmEnvironment: state.psmControlReducer.psm_environment,
    psmInitialized: state.psmControlReducer.psm_initialized
  };
};

const mapDispatch = dispatch => {
  return {
    getMetadata: data => dispatch(getMetadataAction(data)),
    changePsmEnvironment: payload => dispatch(changePsmEnvironment(payload)),
  };
};

export default connect(
  mapState,
  mapDispatch
)(Home);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import styled from 'styled-components';

import { TestCase01VODWithAds } from './TestCaseVOD'
import StandardScenarioContainer from '../../../shared/StandardScenarioContainer';

import { psmInitialized } from '../../../../redux/psmControl/psmControl.action';

const StyledTelemetryScenarioButton = styled.button`
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid white;
    border-radius: 3px;
    color: white;
    background-color: blue;
`;


class TestCase01 extends Component {
  /* Indicates whether the authentication should be enforced when routing to the scenario */
  static requiresAuth = true

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.title = path(['history', 'location', 'state', 'title'], this.props);
    this.state = {
      scenarioChosen: null,
      previousScenario: null,
      scenarioName: null,
    }
  }

  componentDidMount() {
    //initializing prism core
    window.WM.PSM.initPsm(
      {
        platform: 'web',
        brand: 'ncaa',
        subBrand: 'mml-ncaa',
        psmEnvironment: this.props.psmEnvironment,
        countryCode: 'US',
        cookieDomain: 'eleague.com',
        bootstrapVersion: "web/release/3.1.1",
        topics: {},
        contentMetadata: {},
      }
    )
    this.props.psmInitialized();
  }

  componentWillUnmount() {
    if (this.state.scenarioChosen) {
      this.state.scenarioChosen.cleanup()
    }
    this.setState({ scenarioChosen: null, previousScenario: null, scenarioName: null, })
  }

  componentDidUpdate() {
    if (this.state.scenarioChosen) {
      if (this.state.previousScenario) {
        this.state.previousScenario.cleanup()
      }
      this.state.scenarioChosen.setup(this.props.psmEnvironment)

      // initializing prism video/telemetry
      var i = 0;
      if (typeof window.TopPlayer !== 'undefined') {
        window.psmVideo.top2.startMonitoring(window.TopPlayer);
      } else {
        var topObjVerification = setInterval(function () {
          ++i;
          if (typeof window.TopPlayer !== 'undefined') {
            window.psmVideo.top2.startMonitoring(window.TopPlayer);
            clearInterval(topObjVerification);
          }
          if (i >= 18) {
            clearInterval(topObjVerification);
          }
        }, 600);
      }
    }
  }

  changeScenario(scenario, name) {
    this.setState({ previousScenario: this.state.scenarioChosen, scenarioName: name, scenarioChosen: scenario })
  }

  stopPlayerEvents(isTelemetry) {
    TestCase01VODWithAds.stopListeners(isTelemetry)
  }


  render() {
    return (
      <>
        <h4>{this.title}</h4>
        <StandardScenarioContainer>
          {this.state.scenarioChosen ? this.state.scenarioChosen.html() : null}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <StyledTelemetryScenarioButton style={this.state.scenarioName === 'VOD With Ads' ? { backgroundColor: 'green' } : null} onClick={() => this.changeScenario(TestCase01VODWithAds, 'VOD With Ads')}>Play VOD</StyledTelemetryScenarioButton>

            <StyledTelemetryScenarioButton style={this.state.scenarioName === 'VOD With Ads' ? { backgroundColor: 'red' } : { cursor: 'not-allowed', backgroundColor: '#d1e3fe' }} onClick={() => this.stopPlayerEvents(false)}>End events from DuoApp</StyledTelemetryScenarioButton>
            <StyledTelemetryScenarioButton style={this.state.scenarioName === 'VOD With Ads' ? { backgroundColor: 'red' } : { cursor: 'not-allowed', backgroundColor: '#d1e3fe' }} onClick={() => this.stopPlayerEvents(true)}>End events from PsmTelemetry</StyledTelemetryScenarioButton>
          </div>
        </StandardScenarioContainer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    psmEnvironment: state.psmControlReducer.psm_environment
  }
}

const mapDispatchToProps = dispatch => {
  return {
    psmInitialized: () => dispatch(psmInitialized())
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(TestCase01)

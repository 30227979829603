import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom';

function WrappedComponent({ component: Component, ...props}) {
  const { authContext } = props
  const isAuthenticated = authContext?.authenticatedState === 'authenticated'
    || !Component.requiresAuth

  return isAuthenticated
    ? (<Component {...props} />)
    : (
      <div style={{
        width: '100%',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <h3>This scenario requires authentication. Please log in above.</h3>
      </div>
    )
}

const mapState = state => {
  return state.loginReducer;
}

const AuthWrappedComponent = connect(
  mapState
)(WrappedComponent)

export function AuthenticatedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (<AuthWrappedComponent component={Component} {...props} />)}
    />
  );
}

import React from 'react'
import styled from 'styled-components';
import { useState } from 'react';
const StyledTelemetryScenarioButton = styled.button`
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid white;
    border-radius: 3px;
    color: white;
    background-color: red;
`;

function ErrorTriggerSelector() {
  const [eventError, setEventError] = useState("mediaError")
  const dispatchError = () => { 
    if (window.TopPlayer[0] && window.TopPlayer[0]._player && window.TopPlayer[0]._player.events && window.TopPlayer[0]._player.events[eventError]){
      window.TopPlayer[0]._player.events[eventError].dispatch()
    } else {
      console.log("TopPlayer not found so error dispatch unsuccessful")
    }
   }
  return (
    <div>
    <hr />
      <select value={eventError} onChange={(e) => setEventError(e.target.value)}>
        <option>mediaError</option>
        <option>contentError</option>
        <option>adError</option>
        <option>playerError</option>
      </select>
      <StyledTelemetryScenarioButton onClick={()=> dispatchError()} >Trigger {eventError}</StyledTelemetryScenarioButton> on FIRST player (ie: TopPlayer[0])
    </div>
  )
}

export default ErrorTriggerSelector
// @flow
export const LOGIN_PROVIDER = 'login/LOGIN_PROVIDER';
export const LOGOUT_PROVIDER = 'login/LOGOUT_PROVIDER';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const PREPARE_AUTH = 'login/PREPARE_AUTH';
export const AUTH_ERROR = 'login/AUTH_ERROR';
export const AUTHORIZE = 'login/AUTHORIZE';
export const AUTHORIZE_SUCCESS = 'login/AUTHORIZE_SUCCESS';
export const PREPARE_AUTH_SUCCESS = 'login/PREPARE_AUTH_SUCCESS';

export const authorizeAction = (payload: Object) => {
  return {
    type: AUTHORIZE,
    payload,
  };
};

export const authorizeSuccess = (payload: Object) => {
  return {
    type: AUTHORIZE_SUCCESS,
    payload,
  };
};

export const authError = (error: Object) => {
  return {
    type: AUTH_ERROR,
    error,
  };
};

export const prepareAuthAction = () => {
  return {
    type: PREPARE_AUTH,
  };
};
export const prepareAuthSuccess = (payload: Object) => {
  return {
    type: PREPARE_AUTH_SUCCESS,
    payload,
  };
};

export const loginAction = () => {
  return {
    type: LOGIN_PROVIDER,
  };
};

export const loginSuccess = (payload: Object) => {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT_PROVIDER,
  };
};

// @flow
import { combineReducers } from 'redux';
import loginReducer from './login/login.reducer';
import metadataReducer from './getMetadata/getMetadata.reducer';
import psmControlReducer from './psmControl/psmControl.reducer';

export default combineReducers({
  loginReducer,
  metadataReducer,
  psmControlReducer,
});

// @flow
import React from 'react';
import { Navbar, Nav, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LoginProvider from './Login';


const HeaderContainer = styled.div`
  margin-bottom: 1rem;
`;

const Header = () => (
  <HeaderContainer>
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand>DuoApp</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link className="nav-link" to="/">
            Home
          </Link>
        </Nav>
        <Form inline>
          <LoginProvider />
        </Form>
      </Navbar.Collapse>
    </Navbar>
  </HeaderContainer>
);

export default Header;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';
import styled from 'styled-components';
import { ClipWithAds } from './ClipWithAds'
import { LiveWithAds } from './LiveWithAds'
import { VODWithAds } from './VODWithAds'
import StandardScenarioContainer from '../../shared/StandardScenarioContainer';
import { psmInitialized } from '../../../redux/psmControl/psmControl.action';
import ErrorTriggerSelector from '../../shared/ErrorTriggerSelector';
import asyncInterval from './asyncInterval';


const StyledTelemetryScenarioButton = styled.button`
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid white;
    border-radius: 3px;
    color: white;
    background-color: blue;
`;

class Telemetry extends Component {
  /* Indicates whether the authentication should be enforced when routing to the scenario */
  static requiresAuth = true

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.title = path(['history', 'location', 'state', 'title'], this.props);
    this.state = {
      scenarioChosen: null,
      previousScenario: null,
      scenarioName: null,
      psmMgrLoaded: false,
      playerLoaded: false,
      isLoading: true,
    }
  }

  componentDidMount() {
    this.psmMgrAvailable()
    this.props.psmInitialized();
  }

  componentWillUnmount() {
    this.setState({ scenarioChosen: null, previousScenario: null, scenarioName: null, })
    this.clearTopArray()
  }

  componentDidUpdate() {
    if (this.state.scenarioChosen) {
      this.state.scenarioChosen.setup(this.props.psmEnvironment)
      if(window.TopPlayer && window.TopPlayer.length > 0) {
      } else {
        this.topPlayerArrayAvailable()
      }
    }
  }

  changeScenario(scenario, name) {
    this.setState({ previousScenario: this.state.scenarioChosen, scenarioName: name, scenarioChosen: scenario })
  }

  topPlayerArrayAvailable = async () => {
    this.setState({isLoading: true})
    try {
      await asyncInterval(!!window.TopPlayer, 500)
      this.setState({isLoading: false})
    } catch (error) {
      console.error('error getting topPlayer array-------', error)
    }
  }


  psmMgrAvailable = async () => {
    this.setState({isLoading: true})
    try {
      await asyncInterval(window.psmMgr, 500)
      this.setState({isLoading: false})
    } catch (error) {
      console.error('error getting psmMgr-------', error)
    }
  }

  clearTopArray = () => {
    for (let i = 0; i < window.TopPlayer.length; i++) {
      let player = window.TopPlayer[i];
      player.destroy()
      player = null
    }
    window.TopPlayer = []
  }


  render() {
    return (
      <>
        <h4>{this.title}</h4>
        <p>Click each media type to add a player to the page. You can add multiple players of each type to the page at the same time, and they will each send there own payload data to Doppler.</p>
        <StandardScenarioContainer>
          {this.state.scenarioChosen ? this.state.scenarioChosen.html() : null}
          <div style={this.state.isLoading ? { display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'} : { display: 'none'}}><h1>loading...</h1></div>
          <div style={!this.state.isLoading ? { display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'} : { display: 'none'}}>
            <button onClick={() => this.clearTopArray()}>Remove All Videos</button>
            <StyledTelemetryScenarioButton style={this.state.scenarioName === 'Live With Ads' ? { backgroundColor: 'green' } : null} onClick={() => this.changeScenario(LiveWithAds, 'Live With Ads')}>Live</StyledTelemetryScenarioButton>
            <StyledTelemetryScenarioButton style={this.state.scenarioName === 'VOD With Ads' ? { backgroundColor: 'green' } : null} onClick={() => this.changeScenario(VODWithAds, 'VOD With Ads')}>VOD</StyledTelemetryScenarioButton>
            <StyledTelemetryScenarioButton style={this.state.scenarioName === 'Clip With Ads' ? { backgroundColor: 'green' } : null} onClick={() => this.changeScenario(ClipWithAds, 'Clip With Ads')}>Clip</StyledTelemetryScenarioButton>
          </div>
          <ErrorTriggerSelector></ErrorTriggerSelector>
        </StandardScenarioContainer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    psmEnvironment: state.psmControlReducer.psm_environment
  }
}

const mapDispatchToProps = dispatch => {
  return {
    psmInitialized: () => dispatch(psmInitialized())
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Telemetry)

// @flow
import React from 'react';
import { Container } from 'react-bootstrap';
import { path } from 'ramda';
import AuthContext from './AuthContext';
import { connect } from 'react-redux'


type Props = {
  children: any,
  authData?: {
    authContext: any,
  },
};

const AppContainer = (props: Props) => {
  const { children, authData } = props;
  const authContext = path(['authContext'], authData);
  return (
    <AuthContext.Provider value={authContext}>
      <Container>{children}</Container>
    </AuthContext.Provider>
  );
};

const mapState = state => {
  const { authContext } = state.loginReducer;
  return { authData: { authContext } };
}

export default connect(
  mapState
)(AppContainer)

export const GET_METADATA = 'getMetadata/GET_METADATA';
export const GET_METADATA_SUCCESS = 'getMetadata/GET_METADATA_SUCCESS';
export const GET_METADATA_ERROR = 'getMetadata/GET_METADATA_ERROR';
export const SEARCH_METADATA = 'search/SEARCH_METADATA';

export const searchMetadata = payload => {
  return {
    type: SEARCH_METADATA,
    payload,
  };
};

export const getMetadataAction = payload => {
  return {
    type: GET_METADATA,
    payload,
  };
};

export const getMetadataSuccess = payload => {
  return {
    type: GET_METADATA_SUCCESS,
    payload,
  };
};

export const getMetadataError = error => {
  return {
    type: GET_METADATA_ERROR,
    error,
  };
};

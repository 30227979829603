import { Standard } from '../../../app/Standard'
import { AuthService } from '../../../app/AuthService'
import * as Constants from '../../../app/Constants'
import { Player, PlayerConfigBuilder, ContentEntryBuilder } from '@top/player-block-web'
import React from 'react';
import StandardPlayerContainer from '../../../shared/StandardPlayerContainer';
import { mockedProgramMetadataConvivaTags } from '../../../../utils/mocked'

export class TestCase01VODWithAds  {

  static setup = () => {
    let setupConfig = PlayerConfigBuilder.forSetup()
      .withContainer(document.getElementById('player-container'))
      .withMetadata({ appId: Constants.APP_ID })
      
    Standard.addDebugConfiguration(setupConfig)
  
    this.player = new Player()

    Standard.addEventListeners(this.player)
    this.player.setup(setupConfig.build())

    this.playContent()

    /**
     * Copy a reference of player instance
     * window.topPlayer will be used in Telemetry
     * this.player will be used in DuoApp
     */

    // Copying reference - memory Address
    window.TopPlayer = this.player

    // Shallow copy - miss some function reference
    //window.TopPlayer = Object.assign({}, this.player)
    //window.TopPlayer = { ...this.player }
    
    // Deep copy - too long - RangeError: Maximum size stack limit
    //window.TopPlayer = JSON.parse(JSON.stringify(this.player))
    //window.TopPlayer = clone(this.player)

    console.log("=========================>>> PSM: Reference Object", window.TopPlayer)
  }
  
  static playContent = () => {

    AuthService.authorize().then((result) =>  {

      const playConfig = PlayerConfigBuilder.forPlay()
        .withAds({ profile: 'eaMMDsbsd0' })
        .withMetadata({
          env: 'ite',
          companyID: '369463fd-e0a2-4ce9-9ad4-e698aabe5a77',
        })
        .withConviva({
          customerKey: '8a941d3b161c486d426c0a359d9d39bae51186b5',
          touchstoneUrl: 'https://turner-test.testonly.conviva.com',
          applicationName: 'DuoApp - Player 1 - Web',
          assetName: 'Twitter',
          tags: mockedProgramMetadataConvivaTags
        })
        .build()
        
      const contentEntryOptions = ContentEntryBuilder.forEntryOptions()
        .withAccessTokenType('adobe')
        .withAccessToken(result.token)
        .withPlayConfigOverrides(playConfig)
        .build();

      this.player.playByMediaJson({ mediaId: "test8d7ea84d5b95e9459a7189f39640dd1f124efdda" }, contentEntryOptions)
      this.playEventsSelected()
    })
  }

  static playEventsSelected = () => {
    const version = this.player.config.metadata.playerVersion
    const id = this.player.id
    const idT = window.TopPlayer.id
    
    this.player.events.listen({
      playerReady: function(result) {
          console.log("=========================>>> PSM: Duo App Player Event: playerReady");
      },
      adLoaded: function(result) {
          console.log("=========================>>> PSM: Duo App Ad loaded", result);
      },
      adFinished: function(result) {
          console.log("=========================>>> PSM: Duo App Player Event: adFinished"); 
      },
      contentBeginning: function(result) {
        console.log("=========================>>> PSM: Duo App Player Event: contentBeginning");
        console.log("=========================>>> PSM: Duo App Player version: ", version);
        console.log("=========================>>> PSM: Duo App Player ID player version: ", id);
        console.log("=========================>>> PSM: Duo App Player ID TopPlayer version: ", idT);
      },
      contentCompleted: function(result) {
          console.log("=========================>>> PSM: Duo App Player Event: contentCompleted");  
      },
      contentEnded: function(result) {
          console.log("=========================>>> PSM: Duo App Player Event: contentEnded");
      },
      mediaStarted: function(result) {
          console.log("=========================>>> PSM: Duo App Player Event: mediaStarted");
      },
      mediaLoaded: function(result) {
          console.log("=========================>>> PSM: Duo App Player Event: mediaLoaded");
      },
      mediaSeekingStarted: function(result) {
          console.log("=========================>>> PSM: Duo App Player Event: mediaSeekingStarted");
      },
      mediaSeekingFinished: function(result) {
        console.log("=========================>>> PSM: Duo App Player Event: mediaSeekingFinished");
      },
      mediaStarting: function(result) {
        console.log("=========================>>> PSM: Duo App Player Event: mediaStarting");
      }, 
      mediaPaused: function(result) {
        console.log("=========================>>> PSM: Duo App Player Event: mediaPaused");
      },
      mediaResumed: function(result) {
          console.log("=========================>>> PSM: Duo App Player Event: mediaResumed");
      },
   })
  }

  static stopListeners = (isTelemetry) => {
    if (isTelemetry) {
      window.TopPlayer.events.unlistenAll()
      console.log('=========================>>> PSM: unlistenAll from telemetry')
    } else {
      this.player.events.unlistenAll()
      console.log('=========================>>> PSM: unlistenAll from duoApp')
    }
  }

  static cleanup = () => {
    this.player.destroy()
    this.player = null
  }

  static html = () => (
    <StandardPlayerContainer />
  )
}

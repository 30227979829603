import { convivaCommonData } from "./mocked"

export const getConvivaData = (type) => {
  switch (type) {
    case 'live':
      return { 
        applicationName: 'Live Video - TBS - applicationName',
        assetName: 'Live Video - TBS - assetName',
        ...convivaCommonData
      }
      case 'vod':
      return { 
        applicationName: 'VOD Video - TBS - applicationName',
        assetName: 'VOD Video - TBS - assetName',
        ...convivaCommonData
      }
      case 'clip':
      return { 
        applicationName: 'CLIP Video - TBS - applicationName',
        assetName: 'CLIP Video - TBS - assetName',
        ...convivaCommonData
      }
    default:
      break;
  }
}



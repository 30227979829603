import { Standard } from '../../app/Standard'
import { AuthService } from '../../app/AuthService'
import * as Constants from '../../app/Constants'
import {Player, PlayerConfigBuilder, ContentEntryBuilder } from '@top/player-block-web'
import React from 'react';
import StandardPlayerContainer from '../../shared/StandardPlayerContainer';
import { getConvivaData } from '../../../utils/mockConvivaData'

export class VODWithAds  {

  static setup = () => {
    let setupConfig = PlayerConfigBuilder.forSetup()
      .withContainer(document.getElementById('player-container'))
      .withMetadata({ appId: Constants.APP_ID })
      
    Standard.addDebugConfiguration(setupConfig)
  
    this.player = new Player()
    window.player = this.player
    Standard.addEventListeners(this.player)
    this.player.setup(setupConfig.build())
    if (typeof window.TopPlayer === 'undefined'){
      window.TopPlayer = [];
    }
    window.TopPlayer.push(this.player)

    window.psmMgr.startMonitoringTop2(this.player)
    this.playContent()
  }
  
  static playContent = () => {

    AuthService.authorize().then((result) =>  {

      const playConfig = PlayerConfigBuilder.forPlay()
        .withAds({ profile: 'eaMMDsbsd0' }) //TODO: constant file
        .withMetadata({
          env: 'ite',
          companyID: '369463fd-e0a2-4ce9-9ad4-e698aabe5a77',
        })
        .withConviva(getConvivaData('vod'))
        .build()
        
      const contentEntryOptions = ContentEntryBuilder.forEntryOptions()
        .withAccessTokenType('adobe')
        .withAccessToken(result.token)
        .withPlayConfigOverrides(playConfig)
        .build();

      this.player.playByMediaJson({ mediaId: "test8d7ea84d5b95e9459a7189f39640dd1f124efdda" }, contentEntryOptions)
    })
  }

  static cleanup = () => {
    this.player.destroy()
    this.player = null
  }

  static html = () => (
    <StandardPlayerContainer />
  )
}
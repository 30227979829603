import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import styled from 'styled-components';

import { TestCase02VODWithAds } from './TestCaseVOD_02'
import StandardScenarioContainer from '../../../shared/StandardScenarioContainer';

import { psmInitialized } from '../../../../redux/psmControl/psmControl.action';

const StyledTelemetryScenarioButton = styled.button`
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid white;
    border-radius: 3px;
    color: white;
    height: 50%;
    background-color: blue;
`;


class TestCase02 extends Component {
  /* Indicates whether the authentication should be enforced when routing to the scenario */
  static requiresAuth = true

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.title = path(['history', 'location', 'state', 'title'], this.props);
    this.state = {
      scenarioChosen: null,
      previousScenario: null,
      scenarioName: null,
    }
  }

  componentDidMount() {
    //initializing prism core
    window.WM.PSM.initPsm(
      {
        platform: 'web',
        brand: 'ncaa',
        subBrand: 'mml-ncaa',
        psmEnvironment: this.props.psmEnvironment,
        countryCode: 'US',
        cookieDomain: 'eleague.com',
        bootstrapVersion: "web/release/3.1.1", //TODO: bring to constant file
        topics: {},
        contentMetadata: {},
      }
    )
    this.props.psmInitialized();
  }

  componentWillUnmount() {
    if (this.state.scenarioChosen) {
      this.state.scenarioChosen.cleanup()
    }
    this.setState({ scenarioChosen: null, previousScenario: null, scenarioName: null, })
  }

  componentDidUpdate() {
    if (this.state.scenarioChosen) {
      if (this.state.previousScenario) {
        this.state.previousScenario.cleanup()
      }
      this.state.scenarioChosen.setup(this.props.psmEnvironment)

      
      /**
       * 
       * Method 01 - initializing prism video/telemetry on loading
       *  
       * */ 

      // var i = 0;
      // if (typeof window.TopPlayer1 !== 'undefined') {
      //   window.psmVideo.top2.startMonitoring(window.TopPlayer1);
      //   window.psmVideo.top2.startMonitoring(window.TopPlayer2);
      // } else {
      //   var topObjVerification = setInterval(function () {
      //     ++i;
      //     if (typeof window.TopPlayer1 !== 'undefined') {
      //       window.psmVideo.top2.startMonitoring(window.TopPlayer1);
      //       window.psmVideo.top2.startMonitoring(window.TopPlayer2);
      //       clearInterval(topObjVerification);
      //     }
      //     if (i >= 18) {
      //       clearInterval(topObjVerification);
      //     }
      //   }, 600);
      // }
    }
  }

  changeScenario(scenario, name) {
    this.setState({ previousScenario: this.state.scenarioChosen, scenarioName: name, scenarioChosen: scenario })
  }

  stopPlayerEvents(id) {
    TestCase02VODWithAds.stopListeners(id)
  }


  render() {
    return (
      <>
        <h4>{this.title}</h4>
        <StandardScenarioContainer>
          {this.state.scenarioChosen ? this.state.scenarioChosen.html() : null}
          
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <StyledTelemetryScenarioButton style={this.state.scenarioName === 'VOD With Ads' ? { backgroundColor: 'darkgray' } : null} onClick={() => this.changeScenario(TestCase02VODWithAds, 'VOD With Ads')}>Play VOD</StyledTelemetryScenarioButton>
            <button style={{ backgroundColor: 'green' }} onClick={() => TestCase02VODWithAds.initializationPlayerSpecific(1)}>Start telemetry in Player 01</button>            
            <button style={{ backgroundColor: 'green' }} onClick={() => TestCase02VODWithAds.initializationPlayerSpecific(2)}>Start telemetry in Player 02</button>
            <button style={this.state.scenarioName === 'VOD With Ads' ? { backgroundColor: 'red' } : { cursor: 'not-allowed', backgroundColor: '#d1e3fe' }} onClick={() => this.stopPlayerEvents(TestCase02VODWithAds.player1.id)}>End events from Player 01</button>
            <button style={this.state.scenarioName === 'VOD With Ads' ? { backgroundColor: 'red' } : { cursor: 'not-allowed', backgroundColor: '#d1e3fe' }} onClick={() => this.stopPlayerEvents(TestCase02VODWithAds.player2.id)}>End events from Player 02</button>
          </div>
          
        </StandardScenarioContainer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    psmEnvironment: state.psmControlReducer.psm_environment
  }
}

const mapDispatchToProps = dispatch => {
  return {
    psmInitialized: () => dispatch(psmInitialized())
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(TestCase02)


import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { path } from 'ramda'
import { AuthService } from '../app/AuthService'
import {
  logoutAction,
  loginAction,
  prepareAuthAction,
  loginSuccess,
  authError,
  prepareAuthSuccess
} from '../../redux/login/login.action';

const Logo = styled.img`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const LoginContainer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export class LoginProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageURL: '',
    }
  }

  componentDidMount() {
    // Login is loaded in every component, so initializing there by default
    // which creates a new internal auth instance
    AuthService.init()
    
    // Notify the global app state that Auth preparation has started
    this.props.startPrepareAuth()

    AuthService.prepare()
      .then(context => this.props.finishPrepareAuth(context))
      .catch(error => this.props.authError(error))
  }

  componentDidUpdate(prevProps) {
    const { authContext } = this.props;

    // This update will be triggered by the global redux store state
    // updating
    if (authContext && authContext !== prevProps.authContext) {
      this.getCoBrandImage();
    }
  }

  getCoBrandImage = () => {
    const { authContext } = this.props
    const authenticatedState = path(['authenticatedState'], authContext)

    if (authenticatedState !== 'authenticated') {
      this.setState({ imageURL: '' })
      return
    }

    AuthService.instance
      .buildImageURL({
        context: 'cobranding',
        providerId: authContext.authenticatedProvider.id,
        color: 'white',
        width: 120,
        height: 35,
      })
      .then(({ imageURL }) => {
        this.setState({ imageURL });
      })
  }

  handleLogin = () => {
    // Notify the global app state that Auth login has started
    this.props.startLogin()

    AuthService.login()
      .then(context => this.props.finishLogin(context))
      .catch(error => this.props.authError(error))
  }

  handleLogout = () => {
    // Notify the global app state that Auth logout has started
    this.props.startLogout()

    AuthService.logout()
      .then(context => this.props.finishLogout(context))
      .catch(error => this.props.authError(error))
  }

  updateAuthUI = () => {
    const { authContext, isFetching } = this.props;
    const { imageURL } = this.state;

    const authenticatedState = path(['authenticatedState'], authContext);

    const loginProps = {
      btnText: 'Login',
      btnAction: this.handleLogin,
      btnsClass: 'outline-success',
    }

    if (authenticatedState === 'authenticated') {
      loginProps.btnText = 'Logout';
      loginProps.btnAction = this.handleLogout;
      loginProps.btnsClass = 'outline-light';
    }

    return (
      <span>
        {imageURL && <Logo src={imageURL} alt="brand" />}
        <Button variant={loginProps.btnsClass} onClick={loginProps.btnAction} disabled={isFetching}>
          {loginProps.btnText}
        </Button>
      </span>
    );
  }

  render() {
    return (
      <LoginContainer>
        {this.updateAuthUI()}
      </LoginContainer>
    );
  }
}

const mapState = state => {
  const { loginReducer } = state;
  return loginReducer;
}

const mapDispatch = dispatch => {
  return {
    /* These dispatch actions convey when the Async auth processes have started */
    startLogin: () => dispatch(loginAction()),
    startPrepareAuth: () => dispatch(prepareAuthAction()),
    startLogout: () => dispatch(logoutAction()),

    /* These dispatch actions convey when the Async auth processes have finished */
    finishLogin: authContext => dispatch(loginSuccess(authContext)),
    finishLogout: authContext => dispatch(loginSuccess(authContext)),
    finishPrepareAuth: authContext => dispatch(prepareAuthSuccess({ authContext })),
    authError: error => dispatch(authError(error))
  };
};

export default connect(
  mapState,
  mapDispatch
)(LoginProvider)
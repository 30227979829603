// @flow
import React from 'react';
import styled from 'styled-components';

const Panel = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  :not(:first-child) {
    h2 {
      margin-top: 3rem;
      padding-top: 2rem;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

type Props = {
  data: {
    title: string,
    desc: string,
    uri: string,
  },
  headingLevel: number,
  children: any,
};

const Category = (props: Props) => {
  const {
    data: { title, desc: description },
    headingLevel: level,
    children,
  } = props;
  let Heading = `h${level}`;

  return (
    <Panel key={title}>
      <Heading>{title}</Heading>
      <p>{description}</p>
      {children}
    </Panel>
  );
};

export default Category;

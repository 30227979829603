import React from 'react';
import styled from 'styled-components';

const StyledStandardScenarioContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-Items: center
  flex-wrap: wrap;

  @media only screen and (max-width: 420px) {
    width: 100%;
    padding-top: 99%;
  }
`;

export default function StandardScenarioContainer(props) {
  const { children, ...rest } = props;
  return <StyledStandardScenarioContainer {...rest}>{children}</StyledStandardScenarioContainer>;
}

import { Spinner } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';

const ActivityContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ActivityIndicator = () => (
  <ActivityContainer>
    <Spinner animation="grow" size="sm" variant="primary" />
    <Spinner animation="grow" size="sm" variant="dark" />
    <Spinner animation="grow" size="sm" variant="secondary" />
  </ActivityContainer>
);

export default ActivityIndicator;

import Auth, { AuthConfigBuilder } from '@top/auth-block-web'
class AuthService {
  constructor() {
    this._auth = null
  }

  get instance() {
    return this._auth
  }

  init = () => {
    const builder = new AuthConfigBuilder()
      .withAuthType('clientful')
      .withPlatform('web')
      .withMVPDConfigURL('https://tvem.cdn.turner.com/v2/getConfig?platform=web&brand=TBS')
      .withServiceAppId('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwbGF0Zm9ybSI6IkFsbCIsInByb2R1Y3QiOiJzdXBwb3J0IiwibmV0d29yayI6Im1lZGlhZW5hYmxlbWVudCIsImFwcElkIjoibWVkaWFlbmFibGVtZW50LXN1cHBvcnQtQWxsLW9xeGN2ayJ9.ViCUd4qS7HSyqItdpHJ1Qbj-8k36lcbM97ydmeBkiWI')
      .withClientfulConfiguration('eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJkZTA0NTYxZS1iMTFhLTRlYTgtYTg5NC01NjI3MGM1NmM2MWIiLCJuYmYiOjE1MzcxODkzOTAsImlzcyI6ImF1dGguYWRvYmUuY29tIiwiaWF0IjoxNTM3MTg5MzkwfQ.Z7ny66kaqNDdCHf9Y9KsV12LrBxrLkGGxlYe2XGm6qsw2T-k1OCKC1TMzeqiZP735292MMRAQkcJDKrMIzNbAuf9nCdIcv4kE1E2nqUnjPMBduC1bHffZp8zlllyrN2ElDwM8Vhwv_5nElLRwWGEt0Kaq6KJAMZA__WDxKWC18T-wVtsOZWXQpDqO7nByhfj2t-Z8c3TUNVsA_wHgNXlkzJCZ16F2b7yGLT5ZhLPupOScd3MXC5iPh19HSVIok22h8_F_noTmGzmMnIRQi6bWYWK2zC7TQ_MsYHfv7V6EaG5m1RKZTV6JAwwoJQF_9ByzarLV1DGwZxD9-eQdqswvg')
      .withBrand('TBS')
      .withExperienceCloudId('37D8CAC3-36E0-46D9-B160-CB987896CCEF')
      .withHelpURL('https://www.tbs.com/help')

    const config = builder.build()
    this._auth = new Auth(config)
  }

  prepare = () => {
    if (!this._auth) {
      const msg = '[AuthService:prepare] init not yet called'
      console.error(msg)
      return Promise.reject(msg)
    }

    return this._auth.prepare()
  }

  login = () => {
    if (!this._auth) {
      const msg = '[AuthService:login] init not yet called'
      console.error(msg)
      return Promise.reject(msg)
    }

    return this._auth.login()
  }

  logout = () => {
    if (!this._auth) {
      const msg = '[AuthService:logout] init not yet called'
      console.error(msg)
      return Promise.reject(msg)
    }

    return this._auth.logout()
  }

  authorize = (data = {}) => {
    if (!this._auth) {
      const msg = '[AuthService:authorize] init not yet called'
      console.error(msg)
      return Promise.reject(msg)
    }

    return this._auth
      .authorize({
        channel: 'TBS',
        // Override above defaults with specific overrides from external authorize calls
        ...data
      })
  }
}

const AuthServiceSingleton = new AuthService()

export {
  AuthServiceSingleton as AuthService
}
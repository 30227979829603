import { Standard } from '../../app/Standard'
import { AuthService } from '../../app/AuthService'
import * as Constants from '../../app/Constants'
import {Player, PlayerConfigBuilder, ContentEntryBuilder } from '@top/player-block-web'
import React from 'react';
import StandardPlayerContainer from '../../shared/StandardPlayerContainer';
import { getConvivaData } from '../../../utils/mockConvivaData'

export class LiveWithAds  {

  static setup = () => {
    let setupConfig = PlayerConfigBuilder.forSetup()
      .withContainer(document.getElementById('player-container'))
      .withMetadata({ appId: Constants.APP_ID })
      
      
    Standard.addDebugConfiguration(setupConfig)
  
    this.player = new Player()
    window.player = this.player
    Standard.addEventListeners(this.player)
    this.player.setup(setupConfig.build())
    if (typeof window.TopPlayer === 'undefined'){
      window.TopPlayer = [];
    }
    window.TopPlayer.push(this.player)

    window.psmMgr.startMonitoringTop2(this.player)

    this.playContent()
  }
  
  static playContent = () => {

    AuthService.authorize().then((result) =>  {
      console.log('[LiveWithAds] This Live Scenario does not traffic VPAID ads. Please use the Athon version if testing is desired.')

      const playConfig = PlayerConfigBuilder.forPlay()
        .withAds({
          profile: 'SewsFY0EH5',
          kvps: { 'yo.vp': 'false' } // Note: This is only for the DuoApp and ensures no VPAID ads trafficked live (unsupported)
        })
        .withMetadata({
          env: 'ite',
          companyId: 'de4c1d30-ac22-4669-8824-19ba9a1dc128',
        })
        .withConviva(getConvivaData('live'))
        .build()
        
      const contentEntryOptions = ContentEntryBuilder.forEntryOptions()
        .withAccessTokenType('adobe')
        .withAccessToken(result.token)
        .withPlayConfigOverrides(playConfig)
        .build();

      this.player.playByMediaJson({ mediaId: "tbs-east" }, contentEntryOptions)
    })
  }

  static cleanup = () => {
    this.player.destroy()
    this.player = null
  }

  static html = () => (
    <StandardPlayerContainer />
  )
}
// @flow
import React, { useContext } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { path } from 'ramda';
import AuthContext from '../app/AuthContext';

const StyledRow = styled(Row)`
  margin-bottom: -30px;
`;

const StyledCol = styled(Col)`
  margin-bottom: 30px;
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  height: 100%;

  :hover {
    box-shadow: 4px 2px 10px 1px rgba(0, 0, 0, 0.14);
  }
`;

type Scenario = {
  title: string,
  desc: string,
  uri: string,
  requires: string[],
  tags: string[],
};

type Props = {
  data: Scenario[],
  history: any,
};

const Scenarios = (props: Props) => {
  const { data } = props;
  const authData = useContext(AuthContext);

  const handleURI = (uri: string, scenario: Scenario) => {
    const { history } = props;
    const location = {
      pathname: uri,
      state: { ...scenario },
    };
    history.push(location);
  };

  const buildScenario = (aScenario: Scenario) => {
    const { title, desc: description, uri, requires, tags } = aScenario;
    const authenticatedState = path(['authenticatedState'], authData);
    let lockIcon = <FontAwesomeIcon icon="lock" color="#007bff" pull="right" />;
    let handleOnClick = (uri: string) => {};

    if (!requires.includes('auth') || authenticatedState === 'authenticated') {
      lockIcon = null;
      handleOnClick = handleURI;
    }
    
    
    let versionDescription = process.env.REACT_APP_BRANCH ? process.env.REACT_APP_BRANCH : (process.env.REACT_APP_LOCAL ? 'Local file' : description) 
    if(tags.includes('testing')) {
      versionDescription = description;
    }

    return (
      <StyledCard key={title} onClick={() => handleOnClick(uri, aScenario)}>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{versionDescription}</Card.Text>
          {lockIcon}
        </Card.Body>
      </StyledCard>
    );
  };

  return (
    <StyledRow xs={1} md={2} lg={3}>
      {data.map((value, index) => (
        <StyledCol key={index}>{buildScenario(value)}</StyledCol>
      ))}
    </StyledRow>
  );
};

export default withRouter(Scenarios);

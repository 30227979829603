import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import METADATA from '../metadata/data.json';

import {
  GET_METADATA,
  SEARCH_METADATA,
  getMetadataSuccess,
  getMetadataError,
} from '../redux/getMetadata/getMetadata.action';


const filterScenarios = (filter = '', categories = []) => {
  const SPACES_EXP = /\s/g;
  const QUERY = filter.toLocaleLowerCase().replace(SPACES_EXP, '');
  if (filter.length <= 1) return categories;
  const result = categories
    .map(category => {
      const fSections = category.sections
        .map(section => {
          const fScenarios = section.scenarios.filter(scenario => {
            return (
              scenario.title
                .toLocaleLowerCase()
                .replace(SPACES_EXP, '')
                .includes(QUERY) ||
              scenario.desc
                .toLocaleLowerCase()
                .replace(SPACES_EXP, '')
                .includes(QUERY) ||
              scenario.tags.includes(QUERY)
            );
          });
          return fScenarios.length ? { ...section, scenarios: fScenarios } : null;
        })
        .filter(item => !!item);
      return fSections.length ? { ...category, sections: fSections } : null;
    })
    .filter(item => !!item);
  return result;
};

export const getMetadata = action$ =>
  action$.ofType(GET_METADATA).pipe(
    switchMap(() => {
      return of(METADATA);
    }),
    map(({ categories }) => {
      return getMetadataSuccess(categories);
    }),
    catchError(error => {
      return getMetadataError(error);
    })
  );

export const searchMetadata = (action$, state) =>
  action$.ofType(SEARCH_METADATA).pipe(
    debounceTime(300),
    switchMap(({ payload }) => {
      const metadata = METADATA;
      return of(filterScenarios(payload, metadata.categories)).pipe(
        map(categories => {
          return getMetadataSuccess(categories);
        }),
        catchError(error => {
          return getMetadataError(error);
        })
      );
    })
  );

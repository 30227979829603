export const CHANGE_PSM_ENVIRONMENT = "PSM_CONTROL/CHANGE_PSM_ENVIRONMENT";
export const PSM_INITIALIZED = "PSM_CONTROL/PSM_INITIALIZED";

export const changePsmEnvironment = payload => {
    return {
      type: CHANGE_PSM_ENVIRONMENT,
      payload,
    };
};

export const psmInitialized = () => {
    return {
      type: PSM_INITIALIZED,
    }
}
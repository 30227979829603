// @flow
import {
  PREPARE_AUTH,
  LOGOUT_PROVIDER,
  LOGIN_PROVIDER,
  LOGIN_SUCCESS,
  AUTH_ERROR,
  AUTHORIZE,
  AUTHORIZE_SUCCESS,
  PREPARE_AUTH_SUCCESS,
} from './login.action';

const initialState = {
  authContext: null,
  isFetching: false,
  error: null,
  authBlock: null,
};

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case AUTHORIZE:
      return { ...state, isFetching: true };
    case AUTHORIZE_SUCCESS:
      return {
        ...state,
        playerOptions: action.payload,
        isFetching: false,
        error: null,
      };
    case PREPARE_AUTH:
    case LOGIN_PROVIDER:
    case LOGOUT_PROVIDER:
      return { ...state, isFetching: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authContext: action.payload,
        error: null,
      };
    case PREPARE_AUTH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authContext: action.payload.authContext,
        error: null,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const StyledStandardPlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  div {
    max-width: 400px !important;
  }
`;

export default function StandardPlayerContainer({ readyFunc, passedRef }) {
  const playerWrapperRef = useRef(null);

  useEffect(() => {
    if (readyFunc) {
      readyFunc(playerWrapperRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerWrapperRef]);

  return (
    <>
    <StyledStandardPlayerContainer
      id="player-container"
      ref={passedRef ? passedRef : playerWrapperRef}
    ></StyledStandardPlayerContainer>
    <StyledStandardPlayerContainer
      id="player-container2"
      ref={passedRef ? passedRef : playerWrapperRef}
    ></StyledStandardPlayerContainer>
    </>
    
  );
}
